.click:hover {
    background: #ff5858e8;
    transform: scale(1.2);
   
    transition-duration: 1s;
}

.click{
    background: #e3254fbd;
}
.searchButtons{
    gap:5px !important;
    float: right !important;
    padding: 5px;
}


/* .refershButton{
    float:right;
    
    background: #e2ecf2;
    border-radius: 20%;

    transition:  0.5s;

}
.refershButton:hover{
  

    background: #ed1d24;
    color: #fff;
    

}

.refershButton.active {
    background-color: #ecfbfa;
    color: #fff;
}
.refershbutton-right{
    float:right;
    margin:10px;
} */
.refresh:hover{
    animation-name: spin;
    animation-duration: 300ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}
.refreshButton:hover .refreshIcon {
    animation: spin 1.5s linear infinite;
}
@keyframes spin {
    from {
        transform:rotate(360deg);
    }
    to {
        transform:rotate(0deg);
    }
}