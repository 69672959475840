@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.nunito {
    font-family: "Nunito", serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  .Poppins {
    font-family: "Poppins", serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
  *::-webkit-scrollbar {
    width: 8px; /* Width for vertical scrollbars */
    height: 10px; /* Height for horizontal scrollbars */
  }
  
  /* Track (background) */
  *::-webkit-scrollbar-track {
    background: #f1dcdc;
    /* border-radius: 10px; */
  }
  
  /* Thumb (the scrolling element) */
  *::-webkit-scrollbar-thumb {
    background: #ed1d24a6;
    border-radius: 5px;
  }
  
  /* Hover state for the thumb */
  *::-webkit-scrollbar-thumb:hover {
    background: #ED1D24;
  }