@font-face {
  font-family: "Sicpa";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("SuisseIntl.ttf") format("truetype");
}

@font-face {
  font-family: "Sicpa";
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("SuisseIntl.ttf") format("truetype");
}

.toastStyle {
  font-family: "Sicpa" !important;
  font-size: 13px;
  color: black !important;
}

.css-acwr6o-MuiGrid-root>.MuiGrid-item {
  padding-left: 59px !important;
}

.fade-in-out {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in-out.show {
  opacity: 1;
}

.error {
  color: #ff0000 !important;
  margin-top: 5px;
  margin-left: 5px;
}

.css-h4we56-MuiButtonBase-root-MuiButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-transform: capitalize;
  font-family: Sicpa;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 8px 22px;
  /* border-radius: 8px; */
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #ecfbfa;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: none;
  font-weight: 400;
}

.CustomScrollWidth::-webkit-scrollbar {
  width: 7px !important;
}

.css-14y8c3j-MuiToolbar-root {
  background-color: #FFFFFF !important;
}

.CustomScrollWidth::-webkit-scrollbar-thumb {
  background: #aeaeae !important;
}

.CustomScrollWidth::-webkit-scrollbar-track {
  background: white !important;
}

.css-h4we56-MuiButtonBase-root-MuiButton-root::after {
  box-shadow: 0 0 5px 5px rgba(11, 61, 88, 0.9);
}

.css-h4we56-MuiButtonBase-root-MuiButton-root::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* .css-13gj6jo-MuiButtonBase-root-MuiListItemButton-root {
  border-radius: 0px !important;
  margin: 0px !important;
}

.css-13gj6jo-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  border-right: 5px solid #ED1D24;
  background-color: #FAFAFA !important;
} */

.demo-editor {
  min-height: 500px !important;
}

.css-liz65h-MuiTreeItem-root .MuiTreeItem-content {
  padding-left: 0px !important;
}

/* .ant-steps-item-icon {
  background-color: #E03F45 !important;
  border-color: #E03F45 !important;

}

.ant-steps-icon{
  color: #FFF !important;
} */

.ant-steps-item-icon {
  background-color: #fff !important;
  /* White background for active icon */
  border-color: #e03f45 !important;
}

.ant-steps-icon {
  color: #e03f45 !important;
}

.ant-steps-item-active .ant-steps-item-icon {
  /* Same border color */
  background-color: #e03f45 !important;
  border-color: #e03f45 !important;
}

.ant-steps-item-active .ant-steps-icon {
  color: #fff !important;
  /* Red text color for active icon */
}

.ant-steps-item {
  color: #000 !important;
  /* Default text color for inactive */
}

.ant-steps-item-active {
  color: #fff !important;
  /* White text color for active */
}

.steps-content {
  margin-top: 45px !important;
  /* margin-bottom: 45px !important; */
}

.css-jjtu05 {
  background-color: white !important;
}

.css-zyqpnm {
  background-color: #ffffff !important;
}